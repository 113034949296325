<template>
  <v-row class="ma-0">
    <v-col cols="12" class="pa-0">
      <v-toolbar
        color="blue-grey lighten-5"
        dense
        flat
      >
        <v-tabs color="primary" active-class="grey lighten-4" v-model="tabs_details_visita">
          <v-tab>
            <v-icon size="16" class="mr-1">mdi-text-box-check-outline</v-icon>
            <span>Detalles</span>
          </v-tab>
          <v-tab v-if="visita.fotos != null">
            <v-icon size="16" class="mr-1">mdi-image-multiple-outline</v-icon>
            <span>Anaqueles</span>
          </v-tab>
        </v-tabs>
      </v-toolbar>
    </v-col>
    <v-col cols="12" :class="{'px-6': !$vuetify.breakpoint.xsOnly}">
      <v-tabs-items v-model="tabs_details_visita" class="tabs-general-vendedor--items">
        <v-tab-item>
          <v-row>
            <v-col cols="12" sm="12" md="6">
                <v-card flat>
                  <v-card-text class="pt-0 pl-0">
                    <v-row>
                      <v-col cols="12" class="pr-0 pt-0" style="height: 38rem">
                         <view-maps :points="markers" :loadData="false" :centerable="center" :zoom="zoom_visita" />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="6" class="pl-0">
              <v-card flat tile class="fill-height">
                <v-card-text class="grey lighten-5 blue-grey--text">
                  <v-row class="px-6">
                    <v-col cols="12">
                      <v-card-title class="caption text-uppercase d-block blue-grey--text text--darken-3 font-weight-bold pl-0 py-0">Descripción</v-card-title>
                    </v-col>
                  </v-row>
                  <v-row class="px-6">
                    <template v-if="visita.cli_des">
                      <v-col cols="12">
                        <span class="caption text-uppercase d-block">
                          <v-icon size="18" color="icono" class="mt-n1">mdi-account-tie</v-icon>
                          Cliente
                        </span>
                        <span class="font-weight-bold" v-text="visita.cli_des+' ('+visita.co_cli+')'" />
                      </v-col>
                      <v-col cols="12" class="py-1">
                        <v-divider />
                      </v-col>
                    </template>
                    <template>
                      <v-col cols="12">
                        <span class="caption text-uppercase d-block">
                          Pedido
                        </span>
                        <v-icon
                          size="18"
                          class="mt-n1"
                          color="icono"
                          left
                          v-text="visita.pedido === false ? 'mdi-text-box-remove-outline' : 'mdi-text-box-check-outline'"
                        />
                        <span
                          class="font-weight-bold"
                          v-text="visita.pedido === false ? 'Sin Pedido' : 'Pedido Registrado'"
                        />
                      </v-col>
                      <v-col cols="12" class="py-1">
                        <v-divider />
                      </v-col>
                    </template>
                  </v-row>
                  <v-row class="px-6 py-2">
                    <v-col cols="12" sm="4" md="4">
                      <span class="caption text-uppercase d-block">
                        Fecha
                      </span>
                      <v-icon size="18" color="icono" left class="mt-n1">mdi-calendar-month-outline</v-icon>
                      <span class="font-weight-bold">{{visita.fecha | smartDate}}</span>
                    </v-col>
                    <v-col cols="6" sm="4" md="4">
                      <span class="caption text-uppercase d-block">
                        Inicio
                      </span>
                      <v-icon size="18" color="icono" left class="mt-n1">mdi-clock-outline</v-icon>
                      <span class="font-weight-bold" >{{horas_visitas.start}}</span>
                    </v-col>
                    <v-col cols="6" sm="4" md="4">
                      <span class="caption text-uppercase d-block">
                        Final
                      </span>
                      <v-icon size="18" color="icono" left class="mt-n1">mdi-clock-outline</v-icon>
                      <span class="font-weight-bold" v-text="horas_visitas.end" />
                    </v-col>
                    <v-col cols="12" class="py-1">
                      <v-divider class="light"/>
                    </v-col>
                  </v-row>
                  <v-row class="px-6 py-2">
                    <v-col cols="12" sm="4" md="4">
                      <span class="caption text-uppercase d-block">
                        Duración
                      </span>
                      <v-icon size="18" color="icono" left class="mt-n1">mdi-alarm-check</v-icon>
                      <span class="font-weight-bold">{{duracion}}</span>
                    </v-col>
                    <v-col cols="6" sm="5" md="5">
                      <span class="caption text-uppercase d-block">
                        Tipo de Visita
                      </span>
                      <v-chip
                        class="font-weight-bold text-uppercase"
                        color="purple-alert"
                        style="overflow: visible !important"
                        small
                        label
                        pill
                        dark
                      >
                        {{ visita.tipo_visita }}
                      </v-chip>
                    </v-col>
                    <v-col cols="12" class="py-1">
                      <v-divider class="light"/>
                    </v-col>
                  </v-row>
                  <v-row v-if="visita.motivo" class="px-6 py-2">
                    <v-col cols="12">
                      <span class="caption text-uppercase d-block">
                        <v-icon small color="icono" class="mt-n1">mdi-comment-outline</v-icon>
                        Motivo
                      </span>
                      <span
                        class="font-weight-bold"
                        v-text="visita.motivo !== null ? visita.motivo : 'S/M'"
                      />
                    </v-col>
                    <v-col cols="12" class="py-1">
                      <v-divider class="light"/>
                    </v-col>
                  </v-row>
                  <v-row class="px-6 py-2">
                    <v-col cols="12">
                      <span class="caption text-uppercase d-block">
                        <v-icon small color="icono" class="mt-n1">mdi-comment-outline</v-icon>
                        Comentario
                      </span>
                      <span
                        class="font-weight-bold"
                        v-text="visita.comentario !== null ? visita.comentario : 'S/C'"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions class="primary lighten-3 white--text d-flex flex-column align-start">
                  <v-row class="px-6 py-2">
                    <v-col cols="12" sm="12" md="6">
                      <span class="caption text-uppercase d-block">
                        <v-icon size="18" color="info" class="mt-n1">mdi-map-marker-outline</v-icon>
                        Ubicación del Cliente
                      </span>
                      <span class="font-weight-bold" v-text="visita.lat_cliente+'/'+visita.lng_cliente" v-if="visita.lat_cliente != null"/>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <span class="caption text-uppercase d-block">
                        <v-icon
                          size="18"
                          class="mt-n1"
                          :color=" distancia.metros < 100 ? 'success' : distancia.metros > 100 && distancia.metros < 300 ? 'warning' : distancia.metros > 300 ? 'red' : 'transparent'"
                        >mdi-map-marker-outline</v-icon>
                        Ubicación del Vendedor
                      </span>
                      <span class="caption font-weight-bold" v-text="visita.lat+'/'+visita.lng" />
                    </v-col>
                    <template v-if="this.visita.lat_cliente != null">
                      <v-col cols="12">
                        <span class="caption text-uppercase">
                          <v-icon size="18" color="grey lighten-4" class="mt-n1">mdi-map-marker-distance</v-icon>
                          Distancia entre el cliente y el Vendedor:
                        </span>
                        <span class="font-weight-bold" v-text="distancia.metros + ' mts.'" />
                      </v-col>
                      <v-col cols="12">
                        <v-alert
                          dense
                          :color=" distancia.metros < 100 ? 'success' : distancia.metros > 100 && distancia.metros < 300 ? 'warning' : distancia.metros > 300 ? 'red' : 'transparent'"
                        >
                          <span class="caption text-uppercase d-flex justify-center white--text font-weight-bold"
                              v-text="distancia.status">
                          </span>
                        </v-alert>
                      </v-col>
                    </template>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <v-row class="pb-12" v-if="visita.fotos != null">
            <v-col
              v-for="(foto,i) in visita.fotos"
              :key="'anaqueles-'+i"
              cols="6"
              md="3"
              class="elevation-2"
            >
              <v-img
                :src="foto"
                :lazy-src="foto"
                height="200"
                @click="show(i)"
                style="cursor: pointer !important"
              />
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
    <vue-easy-lightbox
      :imgs="visita.fotos"
      :visible="show_image"
      :index="index_current"
      @on-index-change="imgIndex"
      @hide="close"
    >
      <template v-slot:toolbar="{toolbarMethods}">
        <div class="toolbar-imagen">
          <div class="toolbar-imagen-btn">
            <v-btn
              color="white"
              plain
              :ripple="false"
              class="pa-1"
              style="min-width: 0px !important"
              @click="toolbarMethods.zoomIn"
            >
              <v-icon>mdi-magnify-plus-outline</v-icon>
            </v-btn>
          </div>
          <div class="toolbar-imagen-btn">
            <v-btn
              color="white"
              plain
              :ripple="false"
              class="pa-1"
              style="min-width: 0px !important"
              @click="toolbarMethods.zoomOut"
            >
              <v-icon>mdi-magnify-minus-outline</v-icon>
            </v-btn>
          </div>
          <div class="toolbar-imagen-btn">
            <v-btn
              color="white"
              plain
              :ripple="false"
              class="pa-1"
              style="min-width: 0px !important"
              @click="downloadImagen"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </div>
          <div class="toolbar-imagen-btn">
            <v-btn
              color="white"
              plain
              :ripple="false"
              class="pa-1"
              style="min-width: 0px !important"
              @click="toolbarMethods.rotateLeft"
            >
              <v-icon>mdi-rotate-left</v-icon>
            </v-btn>
          </div>
          <div class="toolbar-imagen-btn">
            <v-btn
              color="white"
              plain
              :ripple="false"
              class="pa-1"
              style="min-width: 0px !important"
              @click="toolbarMethods.rotateRight"
            >
              <v-icon>mdi-rotate-right</v-icon>
            </v-btn>
          </div>
        </div>
      </template>
    </vue-easy-lightbox>
    <!-- <v-dialog v-model="show_image" :overlay-opacity="0.9" content-class="elevation-0">
      <v-btn icon @click="close" style="position: absolute; right:15px; top: 5px">
        <v-icon color="white">mdi-close</v-icon>
      </v-btn>
      <v-carousel v-model="index_current" hide-delimiters v-if="visita.fotos != null">
        <v-carousel-item
         v-for="(imagen,i) in visita.fotos"
          :key="imagen"
        >
          <v-img
            :src="imagen"
            height="100%"
            max-height="100hv"
            contain
          />
        </v-carousel-item>
      </v-carousel>
    </v-dialog> -->
  </v-row>
</template>
<script>
import { gmapApi } from 'gmap-vue';
import { getIconUrl } from '@/widgets/iconGenerator'
import moment from 'moment'
import VueEasyLightbox from 'vue-easy-lightbox'
export default {
  name: 'ItemHistoria',
  props:{
    visita: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    ViewMaps: () => import(
      /* webpackChunkName: "view-maps" */
      '@/widgets/ViewMaps'
    ),
    VueEasyLightbox
  },
  data: () => ({
    tabs_details_visita: '',
    center: { lat: 8.955582, lng: -65.3220065 },
    zoom_visita: 15,
    show_image:false,
    index_current: 0,
    distancia:{
      metros: '',
      status: '',
    },
    icon: ''
  }),
  methods:{
    close() {
      this.show_image = false;
    },
    imgIndex(oldIdx, Idx) {
      this.index_current = Idx;
    },
    show(i) {
      this.index_current = i;
      setTimeout(() => {
        this.show_image = true;
      }, 90);
    },
    verPedido(item) {
      this.$emit('showPedido', {Numero: item});
    },
    downloadImagen() {
      let image_name = this.visita.fotos[this.index_current].split('/').pop();
      var imagenLink = document.createElement('a');
      imagenLink.href = this.visita.fotos[this.index_current];
      imagenLink.setAttribute('download', image_name);
      imagenLink.setAttribute('target', '_blank');
      document.body.appendChild(imagenLink);
      imagenLink.click();
      imagenLink.remove();
    },
  },
  computed:{
    google: gmapApi,
    markers () {
      let color =this.distancia.metros < 100 ? '#4CAF50' : this.distancia.metros > 100 && this.distancia.metros < 300 ? '#FB8C00' : this.distancia.metros > 300 ? '#FF5252' : 'transparent'
      return [
        {
          position:{
            lat: parseFloat(this.visita.lat.trim()),
            lng: parseFloat(this.visita.lng.trim()),
          },
          icon:{
            markerColor: color,
            statusColor: color,
          },
        },
        {
          position:{
            lat: this.visita.lat_cliente !== null ? parseFloat(this.visita.lat_cliente.trim()) : 0,
            lng: this.visita.lng_cliente !== null ? parseFloat(this.visita.lng_cliente.trim()) : 0,
          },
          icon:{
            markerColor: this.visita.lat_cliente !== null ? '#00CAE3' : 'transparent',
            statusColor: this.visita.lat_cliente !== null ? '#00CAE3' : 'transparent',
          },
        }
      ]
    },
    icono() {
      return getIconUrl('#EDC10A', '#000000');
    },
    horas_visitas() {
      return {
        start: moment(`${this.visita.fecha} ${this.visita.hora_inicio}`).format('hh:mm a'),
        end: moment(`${this.visita.fecha} ${this.visita.hora_fin}`).format('hh:mm a')
      }
    },
    duracion () {
      const inicio = moment(`${this.visita?.fecha} ${this.visita?.hora_inicio}`);
      const fin = moment(`${this.visita?.fecha} ${this.visita?.hora_fin}`);
      const duracion = fin.diff(inicio)

      const hours = Math.floor(moment.duration(duracion).asHours()).toString()
      return `${ hours.length < 2 ? hours.padStart(2, '0') : hours }:${ moment.utc(duracion).format('mm:ss') }`
    },
  },
  watch:{
    visita (val) {
      this.$gmapApiPromiseLazy().then(() => {
        if(val.lat_cliente !== null && val.lng_cliente!==null) {
          var visita = new this.google.maps.LatLng(this.markers[0].position.lat, this.markers[0].position.lng);
          var cliente = new this.google.maps.LatLng(this.markers[1].position.lat, this.markers[1].position.lng);

          this.distancia.metros = this.google.maps.geometry.spherical.computeDistanceBetween(visita, cliente).toFixed(2);
          this.distancia.status = this.distancia.metros < 100 ? 'Dentro del rango establecido' :
                                  this.distancia.metros > 100 && this.distancia.metros < 300 ? 'Supera el rango establecido' :
                                  this.distancia.metros > 300 ? 'Fuera del Lugar' : null

        }
      });
      this.center = { lat: this.markers[0].position.lat, lng: this.markers[0].position.lng };
      this.zoom_visita = 8;
      this.tabs_details_visita = 0
    }
  },
  mounted () {
     this.$gmapApiPromiseLazy().then(() => {
        if(this.visita.lat_cliente !== null && this.visita.lng_cliente !== null){
          var visita = new this.google.maps.LatLng(this.markers[0].position.lat, this.markers[0].position.lng);
          var cliente = new this.google.maps.LatLng(this.markers[1].position.lat, this.markers[1].position.lng);

          this.distancia.metros = this.google.maps.geometry.spherical.computeDistanceBetween(visita, cliente).toFixed(2);
          this.distancia.status = this.distancia.metros < 100 ? 'Dentro del rango establecido' :
                                  this.distancia.metros > 100 && this.distancia.metros < 300 ? 'Supera el rango establecido' :
                                  this.distancia.metros > 300 ? 'Fuera del Lugar' : null

          this.center = { lat: this.markers[0].position.lat, lng: this.markers[0].position.lng };
          this.zoom_visita = 8;
        }
    });
  },
  filters:{
    hora(val){
      return val != "null" ? moment(val).format('hh:mm a') : '';
    }
  }
}
</script>
