<template>
  <v-container id="clientes-ubicacion" tag="section" class="pa-0" style="max-width: 100%;">
    <!--  SECTION MAP -->
    <v-row no-gutters>
      <v-col cols="12" sm="12" :style="height_mapa">
        <view-maps :points="markers" :action="true" :loadData="loading" info :centerable="center_cli" :animation="center_cli"
          :area="showCustomVisit" @exportar="exportarClientes" @details="detailsClientes" :iconSize="sizeIcon"
          :zoom="zoom_map" />
        <v-overlay v-if="loading" :value="true" color="white" absolute opacity="1" key="mapaClientesOverlay"
          style="z-index: 1002 !important">
          <div class="d-flex flex-column justify-center">
            <v-progress-circular indeterminate size="64" color="primary" class="mx-6"></v-progress-circular> <br>
            <span class="primary--text py-5">Obteniendo Datos</span>
          </div>
        </v-overlay>
      </v-col>
    </v-row>

    <div style="position: absolute; z-index: 20; top: 0px; right: 120px; background-color: white; border-radius: 4px; margin-top: 10px;">
      <consultar-fecha-mapa @consultar="getData" />
    </div>
    <!--- ------------------------------------------------------------------------------------------>
    <!--- ------------------------------------------------------------------------------------------>
    <!-- ######LISTA DE VISITAS DE UN VENDEDOR #####-->
    <v-navigation-drawer v-model="showDetailsVend" v-show="showInfoData" absolute color="grey lighten-5" width="30vw"
      class="elevation-1">
      <v-app-bar dark color="blue-grey">
        <span>VENDEDOR:</span>
        <v-toolbar-title class="ml-2 text-h5">{{ seller.ven_des }} ({{ seller.co_ven }})</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="showInfoData = false">
          <v-icon>mdi-chevron-left-circle</v-icon>
        </v-btn>
      </v-app-bar>
      <div class="ma-3 pl-3">
      <label class="color:red">Fecha: {{ today }}</label>
        <br>
        <label>Hora Inicio: {{ header.hora_inicio ? header.hora_inicio : 'Sin datos' }}</label>
        <label class="px-2">/</label>
        <label>Hora fin: {{ header.hora_fin ? header.hora_fin : 'Sin datos' }}</label>
        <br>
        <label>Clientes Visitados: {{ header.cv ? header.cv : '0' }} de {{ header.cp ? header.cp : '0' }}</label>
        <label style="color:orange"> - {{ vfp }}</label>
        <label> / Efectividad: {{ header.evi ? header.evi : '0' }}%</label>
      </div>
      <v-divider class="mb-4"></v-divider>
      <v-row>
        <v-col cols="12" class="pl-0">
          <v-timeline align-top dense class="pt-0 mt-4">
            <template v-if="sellersWithVisits">
              <v-timeline-item v-for="(visita, i) in sellersWithVisits" :color="visita.visita ? visita.color : 'red'" fill-dot
                icon="mdi-map-marker-radius" small class="mb-4">
                <v-row class="pt-3 pointer-cursor">
                  <v-col cols="12" sm="12" class="pa-0">
                    <v-card flat tile class="fill-height">
                      <v-card-text class="grey lighten-5 blue-grey--text pa-0">
                        <v-row class="py-0" @click="visita.visita ? centerCliente(visita) : ''">
                          <template>
                            <v-col cols="12" class="pb-1">
                              <span class="font-weight-bold primary--text">
                                {{ i + 1 }} - {{ visita.cli_des ? (visita.cli_des || visita.nombre_comercial) : 'No aqui' }} {{  visita.co_cli ? `(${visita.co_cli})` : '' }}
                              </span>
                            </v-col>
                          </template>
                        </v-row>
                        <div v-if="visita.visita" class="my-3">
                        <v-row class="py-0"  @click="centerCliente(visita)">
                          <v-col cols="12" class="py-0">
                            <span class="caption font-weight-medium">
                              <v-icon size="16" color="blue-grey" class="mt-n1">mdi-clock-outline</v-icon>
                              Inicio:
                            </span>
                            <span class="">{{ visita.visita.hora_inicio }}</span>
                          </v-col>
                          <v-col cols="12" class="py-0">
                            <span class="caption font-weight-medium">
                              <v-icon size="16" color="blue-grey" class="mt-n1">mdi-clock-outline</v-icon>
                              Final:
                            </span>
                            <span class="">{{ visita.visita.hora_fin }}</span>
                          </v-col>
                          <v-col cols="12" class="py-0">
                            <span class="caption font-weight-medium">
                              <v-icon size="16" color="blue-grey" class="mt-n1">mdi-alarm-check</v-icon>
                              Duración:
                            </span>
                            <span class="">{{ visita_duracion(visita.visita.hora_inicio, visita.visita.hora_fin) }}</span>
                          </v-col>

                          <v-col cols="12" class="py-0" v-if="visita.visita.cod_pedido">
                            <span class="caption font-weight-medium">
                              <v-icon size="16" color="blue-grey" class="mt-n1">mdi-map-marker-radius-outline</v-icon>
                              Punto de visita:
                            </span>
                            <span class="">{{ visita.visita.cod_pedido }}</span>
                          </v-col>

                          <v-col cols="12" class="py-0">
                            <span class="caption font-weight-medium">
                              <v-icon size="16" color="blue-grey" class="mt-n1">mdi-cart-check</v-icon>
                              Tipo Visita:
                            </span>
                            <span class="">{{ visita.visita.tipo_visita ? visita.visita.tipo_visita: 'N/A' }}</span>
                          </v-col>
                        </v-row>
                        </div>
                        <div v-else class="my-3">
                          <v-row>
                            <v-col cols="12" class="py-0">
                            <span class="caption font-weight-medium">
                              <v-icon size="16" color="red" class="mt-n1">mdi-cart-check</v-icon>
                              <label style="color:red">Cliente sin visitar</label>
                            </span>
                          </v-col>
                          </v-row>
                        </div>
                        <v-row class="py-0">
                          <v-col cols="12" class="py-0">
                            <div class="btn-detail text-subtitle-1 rounded-lg my-2" @click="visita.visita ? viewDetails(visita.visita) : ''">
                              <v-icon class="pl-2" center color="white">mdi-file-check-outline</v-icon>
                              Ver detalle
                            </div>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-timeline-item>
            </template>
            <template v-else>
              <v-timeline-item :color="visita.color" fill-dot
                icon="mdi-map-marker-radius" color="red" small class="mb-4">
                <v-row class="pt-3">
                  <v-col cols="12" sm="12" class="pa-0">
                    <v-card flat tile class="fill-height">
                      <v-card-text class="grey lighten-5 blue-grey--text pa-0">
                        <v-row class="py-0">
                          <template>
                            <v-col cols="12" class="pb-1">
                              <span class="font-weight-bold red--text">
                                {{ 1 }} - Sin Visitas
                              </span>
                            </v-col>
                          </template>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-timeline-item>
            </template>
          </v-timeline>
        </v-col>
      </v-row>
    </v-navigation-drawer>

    <!-- ######DETALLES DE CLIENTE #####-->
    <v-navigation-drawer v-model="showDetailsClient" absolute color="grey lighten-5" width="30vw" class="elevation-1">
      <v-app-bar dark color="blue-grey">
        <v-btn icon @click="showDetailsClient = !showDetailsClient">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>{{ cliDetails.cli_des }} ({{ cliDetails.co_cli }})</v-toolbar-title>
      </v-app-bar>
      <v-divider class="mb-4"></v-divider>
      <v-row>

      </v-row>
    </v-navigation-drawer>

    <size-market v-model="sizeIcon" />
    <modal-cliente-export v-model="showClientExport" :clients="clientsExport" />

    <modal-cliente-map v-model="showClientMap" :client="clientDetails" />

    <v-btn fab dark style="position: fixed;  bottom:1rem" color="primary" @click="showInfoData = true">
      <v-icon>mdi-account-cog</v-icon>
    </v-btn>

    <v-dialog v-model="showDetails" max-width="70%" scrollable>
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar dark color="blue-grey" dense flat>
            <v-toolbar-title class="white--text subtitle-text">
              <v-icon color="white" size="20" class="mt-n1 mr-1">mdi-text-box</v-icon>
              <span class="font-weight-regular">
                Detalles de Visita
              </span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="showDetails = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="pa-0 ma-0">
          <detalles-visita :visita="detailsData" class="ma-0" />
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-container>
</template>
<script>
import { get, sync } from 'vuex-pathify'
import moment from 'moment'
import { getMapsUbucation, getVisitasPlanificadas } from '@/services/vendedores/'

const generarColor = () => {
  const h = Math.floor(Math.random() * 360),
    s = Math.floor(Math.random() * 100) + '%',
    l = Math.floor(Math.random() * 50) + '%';
  return `hsl(${h},${s},${l})`;
}

const colorWeekend = {
  0: '#2196F3',
  1: '#C62828',
  2: '#00695C',
  3: '#FDD835',
  4: '#6A1B9A',
  5: '#E91E63',
  6: '#388E3C'
}

export default {
  name: 'ItemRuta',
  components: {
    DetallesVisita: () => import(
      /* webpackChunkName: "detalles-visita" */
      '../ProgramaVisitas/DetallesVisita.vue'
    ),

    ViewMaps: () => import(
      /* webpackChunkName: "view-maps" */
      '@/widgets/ViewMaps'
    ),
    ConsultarRangoFechaMapa: () => import(
      /* webpackChunkName: "consultar-rango-fecha-mapa" */
      '../ConsultarRangoFechaMapa.vue'
    ),
    ConsultarFechaMapa: () => import(
      /* webpackChunkName: "consultar-fecha-mapa" */
      './ConsultarFechaMapa.vue'
    ),
    ModalClienteExport: () => import(
      /* webpackChunkName: "modal-cliente-export" */
      '@/views/Clientes/Components/ModalClienteExport.vue'
    ),
    ModalClienteMap: () => import(
      /* webpackChunkName: "modal-client-map" */
      '@/views/Clientes/Components/ModalClienteMap.vue'
    ),
    SizeMarket: () => import(
      /* webpackChunkName: "size-market" */
      '@/views/Clientes/Components/SizeMarket.vue'
    ),
  },
  props:{
    seller:{
      type: Object,
      default: () => '',
    }
  },
  data: () => ({
    vfp: 0,
    venSelec: {},
    visits: [],
    header: {},
    detailsData: {},
    today: moment().format("YYYY-MM-DD"),
    showDetails: false,
    co_ven: '',
    search_vend: '',
    showInfoData: true,
    loading: false,
    itemsVend: [],
    center_cli: {
      lat: null,
      lng: null,
    },
    action: false,
    clientsExport: [],
    lines: [],
    showClientExport: false,
    sizeIcon: 'mini',
    zoom_map: 7,
    showClientMap: false,
    sellersWithVisits: [],
    cliDetails: {},
    clientDetails: {},
    customVisitas: [],
    showListVend: true,
    showDetailsVend: false,
    showDetailsClient: false,
    showCustomVisit: false,
    visita: {},
    fechas: {
      ini: moment().startOf('week').day(1).format("YYYY-MM-DD"),
      fin: moment().day(6).format("YYYY-MM-DD"),
      selected: 'semana'
    },
    toggle_all: true
  }),
  created() {
    //this.changeFormatDate();
    this.mini = true;
    this.asigSeller();
  },

  methods: {

    changeFormatDate(){
      // Convertir la cadena de fecha a un objeto Date
      const fecha = new Date(this.today.replace(/-/g, '/'));

      // Opciones de formato
      const opciones = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      };

      // Crear un formateador con las opciones
      const formateador = new Intl.DateTimeFormat('es-ES', opciones);

      // Devolver la fecha formateada
      this.today = formateador.format(fecha);
    },

    //Ver Detalle de visita seleccionada
    async viewDetails(vis){

      //Se cambia el formato de la fecha
      const [year, month, day] = vis.fecha.split('-');
      const date = `${day}-${month}-${year}`;
      const visit2 = vis;

      if(visit2)
      this.detailsData = {
      ...visit2,
        lat_cliente: visit2?.lat,
        lng_cliente: visit2?.lng,
        pedido: visit2.cod_pedido
      };
        this.showDetails = true
    },

    async searchData(fecha) {
      this.today = fecha;
      this.changeFormatDate();
      const data = await this.$api.get(`vendedores/${this.seller.user.co_ven}/programavisita2?fecha_inicio=${fecha}&fecha_final=${fecha}`);

      //Se cambia el formato de la fecha
      const [year, month, day] = fecha.split('-');
      const date = `${day}-${month}-${year}`;

      const visitsProgram = data.data.data[date].clientes;
      this.sellersWithVisits = visitsProgram;

      //Función que convierte las horas de inicio en segundos
      function convertirHoraASegundos(hora) {
        const [horas, minutos, segundos] = hora.split(':').map(Number);
        return horas * 3600 + minutos * 60 + segundos;
      }

      if(this.sellersWithVisits){
        // Separar los objetos con y sin 'visita'
        const withVisita = this.sellersWithVisits.filter(item => item.visita);
        const withoutVisita = this.sellersWithVisits.filter(item => !item.visita);
        this.vfp = 0;
        withVisita.map((visit) => {
          visit.color = generarColor();
          if (visit.visita.tipo_visita == 'Captura de clientes') {
            this.vfp++;
          }
        })

        // Ordenar solo los que tienen 'visita'
        withVisita.sort((a, b) => convertirHoraASegundos(a.visita.hora_inicio) - convertirHoraASegundos(b.visita.hora_inicio));

        // Combinar ambos arrays: primero los ordenados con 'visita' y luego los que no tienen 'visita'
        this.sellersWithVisits = [...withVisita, ...withoutVisita];

        this.visits = withVisita;
      }

      this.header = data.data.data[date].datos;
    },

    async asigSeller(){
      const data = await getMapsUbucation({ fecha: moment().format("YYYY-MM-DD") });

      this.venSelec  = data.find((vendedor) => {
        return vendedor.co_ven == this.seller.user.co_ven;
      });

      await this.searchData(moment().format("YYYY-MM-DD"));

      this.getVendMap(this.venSelec, moment().format("YYYY-MM-DD") );
    },

    async getVendMap(vend, fecha) {
      vend.loading = true;
      this.venDetails = {}
      try {
         this.venDetails = {
           visitas:
              this.venDetails = this.visits
        }
        this.showListVend = !this.showListVend
        this.showDetailsVend = true
      } catch (error) {
        console.error({ error })
      } finally {
        vend.loading = false;
      }
    },

    centerCliente(cli) {
      this.action = true;
      if (cli.lat != null && cli.lng != null) {
        this.center_cli = {
          lat: parseFloat(cli.lat.trim()),
          lng: parseFloat(cli.lng.trim()),
        }
        this.zoom_map = 15;
      }
    },

    exportarClientes(vertices) {
      this.clientsExport = [];
      this.clientsExport = vertices;
      this.showClientExport = true;
    },

    async getData({ ini, fin, selected }) {
      this.fechas.ini = ini;
      this.fechas.fin = fin;
      this.fechas.selected = selected;

      this.showListVend = true;
      this.showDetailsVend = false;

      const data = await getMapsUbucation({ fecha: ini });

      this.venSelec = data.find((vendedor) => {
        return vendedor.co_ven == this.seller.user.co_ven;
      });

      await this.searchData(ini);

      this.getVendMap(this.venSelec, ini);
    },

    visita_duracion(init, end) {
      if (init !== null && end !== null) {
        const today = moment().format('YYYY-MM-DD')
        const inicio = moment(`${today} ${init}`);
        const fin = moment(`${today} ${end}`);
        const duracion = fin.diff(inicio)

        const hours = Math.floor(moment.duration(duracion).asHours()).toString()

        return `${hours.length < 2 ? hours.padStart(2, '0') : hours}:${moment.utc(duracion).format("mm:ss")}`
      }
      return null
    },

    detailsClientes(info) {
      this.showClientMap = true
      this.clientDetails = { ...info }
    },
  },

  computed: {
    status: get('app/statusClient'),
    mini: sync('app/mini'),
    height_mapa() {
      return {
        height: this.$vuetify.breakpoint.mobile ? '100vh' : '86vh',
      }
    },
    height_content_cliente() {
      return {
        'max-height': '100vh',
      }
    },
    itemsCustomVisits() {
      return this.customVisitas?.length > 0
        ? this.customVisitas.filter(item => item.active)
        : []
    },
    data_cliente() {
      if (this.itemsVend.length === 0) return []

      return this.itemsVend.filter(cli => {
        if (cli === null || cli.lat === null) return false
        return (cli.status_cuenta_vencida && this.active_status.rojo) || (cli.status_cuenta_dolar && this.active_status.amarillo) || (!cli.status_cuenta_vencida && !cli.status_cuenta_dolar && this.active_status.verde)
      }).filter(vend => {
        const indexVend = this.itemsVend.findIndex(v => v.co_ven === vend.co_ven);
        return indexVend >= 0 ? this.itemsVend[indexVend].active : false;
      })
    },
    markersCustom() {
      const points = []
      if (this.itemsCustomVisits.length > 0) {
        for (let i = 0; i < this.itemsCustomVisits.length; i++) {
          const visit = this.itemsCustomVisits[i];
          visit.clientes.forEach(item => {
            points.push({
              name: item.cli_des,
              co_cli: item.co_cli,
              co_ven: item.co_ven,
              rif: item.rif,
              ven: item.ven_des,
              position: {
                lat: parseFloat(item?.lat?.trim()) ?? null,
                lng: parseFloat(item?.lng?.trim()) ?? null,
              },
              icon: {
                markerColor: visit.color,
                statusColor: '#FFFFFF',
              },
              number: i + 1,
              animation: null,
            })
          })
        }
      }
      return points;
    },
    markers() {

      const data = this.showListVend ? [...this.itemsVend] : this.showDetailsVend ? [...this.venDetails.visitas] : []

      if (this.showCustomVisit) return this.markersCustom

      return data.length > 0
        ? data.map((item, index) => ({
          name: item.cli_des,
          co_cli: item.co_cli,
          co_ven: item.co_ven,
          rif: item.rif,
          ven: item.ven_des,
          position: {
            lat: parseFloat(item.lat.trim()),
            lng: parseFloat(item.lng.trim()),
          },
          icon: {
            markerColor: item.color,
            statusColor: '#ffffff',
          },
          number: index + 1,
          animation: null,

        }))
        : []
    },
    stop(e) {
      // e?.preventDefault();
      console.log(e)
    }
  },
  filters: {
    diaSemana(date) {
      return moment(date).format("dddd, DD MMM")
    },
    truncFrecuencia(val) {
      return val.substr(0, 1);
    }
  }
}
</script>
<style lang="sass">
.pointer-cursor
  cursor: pointer

.open-info-map
  position: fixed
  z-index: 6
  top: 0
  transform: translateX(0%)
  margin-left: -12px

.close-info-map
  position: fixed
  z-index: 6
  top: 0
  transform: translateX(-100%)
  visibility: hidden

.infoClients
  transition: all 5s ease-out
  -webkit-transition: all 5s ease-out

.custom-chip .v-chip--select
  margin-top: 4px !important
  margin-bottom: 4px !important

.btn-detail
  width: 120px
  height: 30px !important
  background-color: #4DB6AC !important
  color: #FFFFFF !important
  font-size: 0.50rem !important
</style>
